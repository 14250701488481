import { Controller } from "@hotwired/stimulus"

/**
 * When a form contains a direct upload filefield, we want to show the
 * submits-with content on the button/input when the direct upload starts, not
 * when the form is submitted (which is the default turbo behavior).
 */
// Connects to data-controller="direct-upload-form"
export default class extends Controller {
  static targets = ["submitter"]

  get submitsWith() {
    return this.submitterTarget?.getAttribute("data-turbo-submits-with")
  }

  connect() {
    this.element.addEventListener("direct-uploads:start", this.handleDirectUploadStart.bind(this))
    this.element.addEventListener("direct-upload:error", this.handleDirectUploadError.bind(this))
    this.element.addEventListener("turbo:submit-end", this.handleTurboSubmitEnd.bind(this))
  }

  handleDirectUploadStart(event) {
    this.setSubmitsWith()
  }

  handleDirectUploadError(event) {
    const { error } = event.detail
    Honeybadger.notify(error)
  }

  handleTurboSubmitEnd(event) {
    this.resetSubmitterText()
  }

  setSubmitsWith() {
    if (!this.submitterTarget || !this.submitsWith) return

    if (this.submitterTarget.matches("button")) {
      this.originalSubmitText = this.submitterTarget.innerHTML
      this.submitterTarget.innerHTML = this.submitsWith
    } else if (this.submitterTarget.matches("input")) {
      const input = this.submitterTarget
      this.originalSubmitText = input.value
      input.value = this.submitsWith
    }
  }

  resetSubmitterText() {
    if (!this.submitterTarget || !this.originalSubmitText) return

    if (this.submitterTarget.matches("button")) {
      this.submitterTarget.innerHTML = this.originalSubmitText
    } else if (this.submitterTarget.matches("input")) {
      const input = this.submitterTarget
      input.value = this.originalSubmitText
    }
  }
}
